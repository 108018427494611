import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
Vue.config.productionTip = false
axios.defaults.baseURL = 'https://ta.tikfriend.com' //正式环境
    //axios.defaults.baseURL = 'https://tech.tikfriend.cn'//测试环境
    // axios.defaults.baseURL = 'https://ta.3cink.com:2019' //开发环境
new Vue({
    router,
    store,
    render: function(h) { return h(App) }
}).$mount('#app')