<template>
  <div>
    <div class="banner" id="banner">
      <div class="slickbanner">
        <div class="item" style="position: relative">
          <a class="img-box" href="#" target="_self">
            <img src="picture/1.jpg" alt="" />
            <div v-if="IsPC()" style=" 
                position: absolute;
                top: 225px;
                left: 243px;
                width: 500px;
                height: 200px;
                text-align: left;
              " >
              <span style="
                  width: 400px;
                  height: 74px;
                  font-size: 50px;
                  font-family: SourceHanSansSC-Bold, SourceHanSansSC;
                  font-weight: bold;
                  color: #ffffff;
                  line-height: 74px;
                ">智慧社区解决方案</span>
              <span style="
                  display: block;
                  margin-top: 60px;
                  width: 755px;
                  height: 72px;
                  font-size: 24px;
                  font-family: SourceHanSansSC-Regular, SourceHanSansSC;
                  font-weight: 400;
                  color: #ffffff;
                  line-height: 36px;
                  text-align: left;
                ">基于互联网，物联网技术将人，设备，服务相结合并以资源共享而打造的社区综合性服务性管理平台</span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="indexcontent">
      <div class="box box-about">
        <div class="container">
          <div class="title flipInX">
            <b>关于我们</b>
          </div>
          <div class="text fadeInUp">
            <p style="text-align: left">
              泉州砾鹰石科技有限公司成立于2016年7月，服务范围涵盖咨询及解决方案、计算机硬件、IT服务、通信及流程服务。砾鹰石将自主研发的智能硬件、灵活多变的应用软件和行业领先的人工智能算法相结合，为客户提供物流系统打印机、智慧社区、全屋智能等智能化解决方案
            </p>
          </div>
          <div class="about" style="display: flex; justify-content: center">
            <ul style="
                display: flex;
                flex-direction: row;

                overflow-x: auto;
              ">
              <li>
                <a class="item item-renyuan fadeInUp">
                  <b>200</b>
                  <p>200多名后勤支撑人员</p>
                </a>
              </li>
              <li>
                <a class="item item-shengchan fadeInUp">
                  <b>40<sup>+</sup></b>
                  <p>40+研发技术人员</p>
                </a>
              </li>
              <li>
                <a class="item item-xilie fadeInUp">
                  <b>40</b>
                  <p>40多项商标</p>
                </a>
              </li>
              <li>
                <a class="item item-tixi fadeInUp">
                  <b>20</b>
                  <p>20余项目著作权</p>
                </a>
              </li>
              <li>
                <a class="item item-yanfa fadeInUp">
                  <b>20</b>
                  <p>20多项专利</p>
                </a>
              </li>
            </ul>
          </div>
          <div class="more fadeInUp">
            <a @click="goabout">查看更多</a>
          </div>
        </div>
      </div>

      <div class="box box-solution">
        <div class="title">
          <b>解决方案</b>
        </div>
        <div class="solution">
          <div class="slick-solution">
            <div v-for="item in bannerList" :key="item.key">
              <div class="item">
                <a class="img-box"></a>
                <a class="maskbg" @click="detailmore(item.type)">
                  <img :src="require(`../../public/picture/${item.img}.png`)" style="width: 100%; height: 100%" />
                  <div class="mask">
                    <b>{{ item.name }}</b>
                    <p></p>
                    <span style="cursor: pointer;z-index: inherit;" @touchstart="detailmore(item.type)"
                      @click="detailmore(item.type)">查看更多</span>
                    <!-- <button @click="detailmore(item.type)">查看更多</button> -->
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box box-product">
        <div class="container">
          <div class="title flipInX">
            <b>产品中心</b>
          </div>
          <div class="product" style="display: flex; justify-content: center">
            <div class="col-xs-6 col-md-3 list fadeInUp" v-for="item in productlist" :key="item.key">
              <div class="item" @click="goproduct(item.type)" style="cursor: pointer">
                <a class="img-box"> <img :src="require(`../../public/picture/productlist/${item.img}.png`)"
                    style="width: 100%; height: 100%" /> </a>
                <a class="mask" style="right: 8%">
                  <p>{{ item.name }}</p>
                </a>
              </div>
            </div>

            <!-- <div class="col-xs-6 col-md-3 list fadeInUp">
              <div
                class="item"
                @click="goproduct('two')"
                style="cursor: pointer"
              >
                <a class="img-box"> </a>
                <a class="mask" style="right: 8%">
                  <p>贴标设备</p>
                </a>
              </div>
            </div>

            <div class="col-xs-6 col-md-3 list fadeInUp">
              <div
                class="item"
                @click="goproduct('three')"
                style="cursor: pointer"
              >
                <a class="img-box"> </a>
                <a class="mask" style="right: 8%">
                  <p>互勾智能设备</p>
                </a>
              </div>
            </div>

            <div class="col-xs-6 col-md-3 list fadeInUp">
              <div
                class="item"
                @click="goproduct('four')"
                style="cursor: pointer"
              >
                <a class="img-box"> </a>
                <a class="mask" style="right: 8%">
                  <p>互勾社区</p>
                </a>
              </div>
            </div> -->

            <!-- <div
              class="col-xs-6 col-md-3 list wow fadeInUp"
              data-wow-duration="1s"
            >
              <div class="item">
                <a class="img-box" href="index8.html">
                  <img
                    class="lazy"
                    data-src="/k331/upload/image/20220808/1659961263646298.jpg"
                    width="430"
                    height="260"
                    alt="产品分类四"
                  />
                </a>
                <a class="mask" href="index8.html">
                  <p>产品分类四</p>
                </a>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="box-news" style="display: none">
        <div class="container">
          <div class="title flipInX">
            <b>新闻资讯</b>
          </div>
          <div class="news">
            <div class="slick-news fadeInUp">
              <div>
                <div class="item">
                  <div class="news-top">
                    <span>1</span>
                    <div class="pic">
                      <a class="img-box" href="index25.html"> </a>
                    </div>
                  </div>
                  <div class="text">
                    <b><a href="index25.html">链接优化的注意事项</a></b>
                    <p>
                      <a href="index25.html">内部链接优化就是对网站的站内链接进行优化，在内链优化中，分配的权重都是不一样的，···</a>
                    </p>
                    <div class="more">
                      <span>2022-08-08</span>
                      <a href="index25.html">查看更多+</a>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div class="item">
                  <div class="news-top">
                    <span>2</span>
                    <div class="pic">
                      <a class="img-box" href="index26.html"> </a>
                    </div>
                  </div>
                  <div class="text">
                    <b><a href="index26.html">提高搜索引擎的抓取频次方法</a></b>
                    <p>
                      <a href="index26.html">新网站上线，是要被搜索引擎收录的，想提高网站的收录，需要能够吸引更多的蜘蛛来爬行···</a>
                    </p>
                    <div class="more">
                      <span>2022-08-08</span>
                      <a href="index26.html">查看更多+</a>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div class="item">
                  <div class="news-top">
                    <span>3</span>
                    <div class="pic">
                      <a class="img-box" href="index27.html"> </a>
                    </div>
                  </div>
                  <div class="text">
                    <b><a href="index27.html">做好前端网页优化，让你的网站浏览量爆满</a></b>
                    <p>
                      <a href="index27.html">对于一个网站来说，这个原则也是适用的，即使你有丰富而有价值的内容，但是顾客半天都···</a>
                    </p>
                    <div class="more">
                      <span>2022-08-08</span>
                      <a href="index27.html">查看更多+</a>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div class="item">
                  <div class="news-top">
                    <span>4</span>
                    <div class="pic">
                      <a class="img-box" href="index28.html"> </a>
                    </div>
                  </div>
                  <div class="text">
                    <b><a href="index28.html">内部链接优化时要注意哪些内容？</a></b>
                    <p>
                      <a href="index28.html">内部链接优化就是对网站的站内链接进行优化，在内链优化中，分配的权重都是不一样的，···</a>
                    </p>
                    <div class="more">
                      <span>2022-08-08</span>
                      <a href="index28.html">查看更多+</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box box-customer" style="display: none">
        <div class="container">
          <div class="title wow flipInX" data-wow-duration="1s">
            <b>合作客户</b>
          </div>
          <div class="customer wow flipInX" data-wow-duration="1s">
            <div class="slick-customer">
              <div>
                <div class="item">
                  <div class="img-box">
                    <img data-lazy="" width="260" height="120" alt="合作客户6" />
                  </div>
                </div>
              </div>

              <div>
                <div class="item">
                  <div class="img-box">
                    <img data-lazy="" width="260" height="120" alt="合作客户5" />
                  </div>
                </div>
              </div>

              <div>
                <div class="item">
                  <div class="img-box">
                    <img data-lazy="" width="260" height="120" alt="合作客户4" />
                  </div>
                </div>
              </div>

              <div>
                <div class="item">
                  <div class="img-box">
                    <img data-lazy="" width="260" height="120" alt="合作客户3" />
                  </div>
                </div>
              </div>

              <div>
                <div class="item">
                  <div class="img-box">
                    <img data-lazy="" width="260" height="120" alt="合作客户2" />
                  </div>
                </div>
              </div>

              <div>
                <div class="item">
                  <div class="img-box">
                    <img data-lazy="" width="260" height="120" alt="合作客户1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerList: [
        { key: 1, img: `122`, name: "智慧社区", type: "one" },
        { key: 2, img: `22`, name: "水果行业自动贴标方案", type: "two" },
        { key: 3, img: `33`, name: "布匹行业自动贴标方案", type: "three" },
        { key: 4, img: `44`, name: "双面胶行业自动贴标方案", type: "four" },
        { key: 5, img: `55`, name: "美缝剂行业自动贴标方案", type: "five" },
      ],
      productlist: [
        { key: 1, img: `print`, name: "打印设备", type: "one" },
        { key: 2, img: `2`, name: "贴标设备", type: "two" },
        { key: 3, img: `intelligence`, name: "互勾智能设备", type: "three" },
        { key: 4, img: `community`, name: "互勾社区", type: "four" },
      ],
    };
  },

  mounted() {
    this.slick();
    this.$refs.scrollRef.scrollTop = 0;
  },
  methods: {
     IsPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = [
            "Android",
            "iPhone",
            "SymbianOS",
            "Windows Phone",
            "iPod",
            "iPad",
        ];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        console.log("=========>", Agents[v], flag);
        return flag;
    },
    goabout() {
      this.$router.push({
        path: "/index1",
      });
    },
    goproduct(name) {
      console.log("=======>", name);
      this.$router.push({
        path: "/index2",
        query: { name: name },
      });
    },
    detailmore(name) {
      console.log("=======>", name);
      this.$router.push({
        path: "/index3",
        query: { name: name },
      });
      // this.$router.push("/index3");
    },
    slick() {
      $(".slick-solution").slick({
        autoplay: true,
        autoplaySpeed: 3000, //以毫秒为单位的自动播放速度
        arrows: true, //上一下，下一页
        fade: false, //启用淡入淡出
        dots: false, //显示点指示符
        speed: 500, //幻灯片/淡入淡出动画速度
        cssEase: "ease", //CSS3动画缓和
        slidesToShow: 5, //显示的幻灯片数量
        slidesToScroll: 1, //要滚动的幻灯片数量
        focusOnSelect: true, //启用选定元素的焦点（单击）
        touchThreshold: 300, //滑动切换阈值，即滑动多少像素后切换
        infinite: true, //无限循环
        lazyLoad: "ondemand", //接受'ondemand'或'progressive'<img data-lazy="img/lazyfonz1.png"/>
        variableWidth: false, //幻灯片宽度自适应
        adaptiveHeight: false, //自适应高度
        rows: 1, //将其设置为1以上将初始化网格模式。使用slidesPerRow设置每行应放置多少个幻灯片
        slidesPerRow: 1, //在通过行选项初始化网格模式时，这会设置每个网格行中的幻灯片数量
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },
  },
};
</script>
