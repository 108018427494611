import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'index',
        component: index
    },
    {
        path: '/team',
        name: 'index',
        component: index
    },
    {
        path: '/index1',
        name: 'index1',
        component: function() {
            return import ('../views/index1.vue')
        }
    },
    {
        path: '/index2',
        name: 'index2',
        component: function() {
            return import ('../views/index2.vue')
        }
    },
    {
        path: '/index3',
        name: 'index3',
        component: function() {
            return import ('../views/index3.vue')
        }
    },
    {
        path: '/index4',
        name: 'index4',
        component: function() {
            return import ('../views/index4.vue')
        }
    },
    {
        path: '/chat',
        name: 'chat',
        component: function() {
            return import ('../views/hgChat.vue')
        }
    },
    {
        path: '/work',
        name: 'work',
        component: function() {
            return import ('../views/hgWork.vue')
        }
    },
    {
        path: '/monitor',
        name: 'monitor',
        component: function() {
            return import ('../views/monitor.vue')
        }
    },
]

const router = new VueRouter({
    mode: "history",
    routes
})

export default router