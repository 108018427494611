 export const menulist = [{
         name: "首页",
         routerpath: "/team",
     },
     {
         name: "企业简介",
         routerpath: "/index1",
     },
     {
         name: "产品中心",
         routerpath: "/index2",
     },
     {
         name: "解决方案",
         routerpath: "/index3",
     },
     {
         name: "服务支持",
         routerpath: "/index4",
     }
 ]